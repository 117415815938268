<template>
    <div class="config-property">
        <div>
            Tipo de cabeçalho utilizado:
        </div>
        <label class="input-radio" v-for="(tipo, idx) in tipos" :key="idx">
            <div class="row">
                <div class="col-sm-1">
                    <input type="radio" :value="tipo.value" name="TIPO_CABECALHO" v-model="value.TIPO_CABECALHO">
                </div>
                <div class="col-sm-11">
                    {{ tipo.label }} <br>
                    <img :src="tipo.img">
                </div>
            </div>
        </label>
    </div>
</template>

<script>
  import IMG_HEADER_CARRINHO_DETALHADO from '../img/HEADER_CARRINHO_DETALHADO.png';
  import IMG_HEADER_CARRINHO_SIMPLIFICADO from '../img/HEADER_CARRINHO_SIMPLIFICADO.png';

  export default {
    name: "TIPO_CABECALHO",
    props: [
      'value'
    ],
    data() {
      return {
        tipos: [
          {
            label: 'Cabeçalho detalhado com informações de crédito e cliente',
            value: 'HEADER_CARRINHO_DETALHADO',
            img: IMG_HEADER_CARRINHO_DETALHADO,
          },
          {
            label: 'Cabeçalho simplificado sem informações de crédito e cliente',
            value: 'HEADER_CARRINHO_SIMPLIFICADO',
            img: IMG_HEADER_CARRINHO_SIMPLIFICADO,
          },
        ]
      }
    }
  }
</script>